import { useAuthStore } from "../store/app/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {

  const authStore = useAuthStore();

  if (authStore.selectedCompany == null) {



    if (authStore.companyWithPermissions.length == 0) {

      return navigateTo('/minhas-empresas')

    }

    if (!authStore.hasMoreCompanies) {

      authStore.selectCompany(authStore.companies[0])

      return
    }

    const companyId = authStore.getCompanyIdOnLocalStorage()

    if (companyId) {

      const company = authStore.companies.find((company) => company.id == companyId)

      if (company) {

        authStore.selectCompany(company)

        return
      }
    }

    return navigateTo('/minhas-empresas')
  }
});
